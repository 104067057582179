import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
import Testimonials from "../components/Testimonials";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import FullWidthImage from "../components/FullWidthImage";
import GoogleMap from "../components/GoogleMap";
import Marker from "../components/Marker";

// eslint-disable-next-line
export const SubpagePageTemplate = ({
  image,
  title,
  heading,
  description,
  venues,
  fullImage,
}) => {
  const heroImage = getImage(image) || image;
  const fullWidthImage = getImage(fullImage) || fullImage;

  const defaultProps = {
    center: {
      lat: 37.5043533,
      lng: 127.0447344
    },
    zoom: 11
  };

  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-7 is-offset-1">
                <h3 className="has-text-weight-semibold is-size-2">
                  {heading}
                </h3>
                <p>{description}</p>
                <div className="columns is-multiline">

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FullWidthImage img={fullWidthImage} imgPosition={"center"} />
    </div>
  );
};

SubpagePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  venues: PropTypes.array,
  description: PropTypes.string,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const SubpagePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <SubpagePageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        venues={frontmatter.venues}
        description={frontmatter.description}
        fullImage={frontmatter.full_image}
      />
    </Layout>
  );
};

SubpagePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default SubpagePage;

export const SubpagePageQuery = graphql`
  query SubpagePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        venues {
          image {
            childImageSharp {
              gatsbyImageData(width: 420, quality: 64, layout: CONSTRAINED)
            }
          }
          lat
          lng
          display
        }
        description
        full_image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
